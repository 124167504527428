import gql from "graphql-tag";
import { MembershipInfo } from "@RHCommerceDev/graphql-client/fragments/membership-info";

export const SessionUser = gql`
  fragment SessionUser on SessionUserType {
    sessionId
    rhuid
    location
    currentCartId
    validAssociate
    location
    rhUser {
      id
      firstName
      lastName
      email
      userType
      gender
      globalSfContactId
      sfContactId
      sfAccountType
      company
      shopperRef
      createdDate
      locale
      defaultShippingAddressId
      postalCode
      akamaiCountryCode
      newUser
    }
    membershipInfo {
      ...MembershipInfo
    }
    cookiePreferences {
      userSavedCookiePreferences
      preferencesFunctionalityCookie
      thirdPartyAnalyticsCookie
      strictlyNecessaryCookie
      analyticsCookie
      userAcceptedAllCookies
      cookieRules
    }
  }
  ${MembershipInfo}
`;
